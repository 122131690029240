<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <!--                        <div class="row text-right">-->
            <!--                            <div class="col-md-12">-->
            <!--                                You are here:-->
            <!--                                <router-link :to="{name:'dashboard'}">-->
            <!--                                    Dashboard-->
            <!--                                </router-link>-->
            <!--                                \-->
            <!--                                Fees-->
            <!--                            </div>-->
            <!--                        </div>-->
            <div class="row">
              <div class="col-6">
                <h4>
                  {{ entrance_id ? 'Edit entrance Exam ' : 'Add entrance exam' }}
                </h4>
                <router-link :to="{ name: 'dashboard' }">
                  Dashboard \
                </router-link>
                <router-link :to="{name: 'entrance-exam'}">
                  Entrance exam \
                </router-link>
                {{ entrance_id ? 'Edit entrance Exam ' : 'Add entrance exam' }}

              </div>
              <!--                        <div class="col-4">-->
              <!--                            <h4>Manage Institution</h4>-->
              <!--                        </div>-->
            </div>
            <div class="row">
              <div class="col-6">
                <v-text-field outlined dense label="Entrance title" v-model="entrance.title" :error="$v.entrance.title.$error">
                </v-text-field>
                <span class="text-danger" v-if="$v.entrance.title.$error">This information is required</span>
              </div>
              <div class="col-6">
                <v-select label="Type" :items="types"
                    outlined dense v-model="entrance.type"
                    item-text="title" :error="$v.entrance.type.$error"
                    item-value="value"></v-select>
                <span class="text-danger" v-if="$v.entrance.type.$error">This information is required</span>
              </div>

              <div class="col-6">
                <v-select
                    label="Setting"
                    :items="pre_registrations" :error="$v.entrance.pre_registration_id.$error"
                    outlined dense
                    v-model="entrance.pre_registration_id"
                    item-text="display_text"
                    item-value="id"
                ></v-select>
                <span class="text-danger" v-if="$v.entrance.pre_registration_id.$error">This information is required</span>
              </div>
             <div class="col-6">
                    <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    v-model="entrance.scheduled_for"
                                    label="Entrance date" :error="$v.entrance.scheduled_for.$error"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined dense
                            ></v-text-field>
                        </template>
                        <v-date-picker :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                v-model="entrance.scheduled_for"
                                @input="menu2 = false"
                        ></v-date-picker>
                    </v-menu>
                    <span class="text-danger" v-if="$v.entrance.scheduled_for.$error">This information is required</span>
                </div>
              <div class="col-6">
                <v-text-field outlined dense v-model="entrance.start_time" :error="$v.entrance.start_time.$error"
                              label="Start time"  type="time">
                </v-text-field>
                  <span class="text-danger" v-if="$v.entrance.start_time.$error">This information is required</span>
              </div>
              <div class="col-6">
                <v-text-field dense outlined label="End time" :error="$v.entrance.end_time.$error"
                              type="time" v-model="entrance.end_time"></v-text-field>
                  <span class="text-danger" v-if="$v.entrance.start_time.$error">This information is required</span>
              </div>



              <div class="col-2">
                <v-switch label="Status" v-model="entrance.is_active"></v-switch>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-right">
                <v-btn
                    class="text-gray btn btn-standard"
                    depressed
                    @click="redirectToPreRegistration"
                >Cancel
                </v-btn>
                <v-btn
                    class="text-white ml-2 btn btn-primary"
                    depressed
                    @click="createAndUpdate"
                    :loading="isBusy"
                >Save
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import EntranceExamService from "@/core/services/entrance-exam/EntranceExamService";
import PreRegistrationSettingService
  from "@/core/services/level/program/pre-registration-setting/PreRegistrationSettingService";
import {API_URL} from "@/core/config";

const entranceService = new EntranceExamService();
const preRegistrationSetting = new PreRegistrationSettingService();
export default {
  name: "CreateOrUpdate",
  validations: {
    entrance: {
      'title': {required},
      'type': {required},
      'pre_registration_id': {required},
        'scheduled_for': {required},
        'start_time': {required},
        'end_time': {required},
    }
  },
  data() {
    return {
      types: [
        {title: 'In premise', value: 'in_premise'},
        {title: 'Online quiz', value: 'quiz'}
      ],
      levels: [],
      programs: [],
      menu2: false,
      menu3: false,
      menu4: false,
      isBusy: false,
      pre_registrations: [],
      entrance: {
        'title': null,
        'type': null,
        'pass_mark': null,
        'pre_registration_id': null,
        'scheduled_for': null,
        'is_conducted': null,
        'result_published': null,
        'is_active': true,
      }
    }
  },
  computed: {
    entrance_id() {
      return this.$route.params.id;
    }
  }, mounted() {
    if (this.entrance_id) {
      this.getEntrance();
    }
    this.getPreRegistrations()
  },
  methods: {
    getEntrance() {
      entranceService.show(this.entrance_id).then(response => {
        this.entrance = response.data.entrance
        // this.getPreregistrationProgramByLevel()
      })
    },
    getPreRegistrations() {
      preRegistrationSetting
          .getAllCurrentSetting().then(response => {
            this.pre_registrations = response.data.setting
          })
    },

    redirectToPreRegistration() {
      this.$router.push({name: "entrance-exam"})
    },
    createAndUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000)
      } else {
        if (this.entrance_id) {
          this.__update();
        } else {
          this.__create()
        }
      }
    },
    __create() {
      entranceService.store(this.entrance).then(response => {
        this.$snotify.success("Entrance exam created")
        this.redirectToPreRegistration();
      })
    },
    __update() {
      entranceService.update(this.entrance.id, this.entrance).then(response => {
        this.$snotify.success("Entrance exam updated")
        this.redirectToPreRegistration();
      })
    },
  }
}
</script>

<style scoped>

</style>